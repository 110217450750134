<template>
  <div class="yard-body" v-if="showLayout">
    <loading-overlay :active="loading" :is-full-page="true" loader="bars" />
    <preferences-modal
      :modal.sync="modalPref"
      :preferencias="preferences"
      :selecteds="currentPreferences"
      :yardId="currentYardId"
      @submit="handleSelecteds"
    />
    <legend-modal
      :viewType="viewType"
      :modal.sync="legendModal"
      :preferencias="currentPreferences"
      :levels="levels"
    />
    <yard-slot-modal 
      :modal.sync="modal" 
      :container="containerData" 
      :ContainerList="ContainerList" 
      :ShowList="ShowList" 
      :NotPositioned="NotPositioned"
      :FgInvalidPosition="FgInvalidPosition"
      />
    <div class="wrapper d-flex align-items-stretch">
      <nav id="detailSidebar">
        <div class="pb-4 pt-1 sidebar-inner-space">
          <ul class="list-unstyled components mb-5 mx-3">
            <li class="mb-1 mt-4">
              <CButton
                block
                size="sm"
                color="secondary"
                class="d-flex align-items-center justify-content-center"
                @click="view = '' , level = 0, preferences = [], viewType = 'level'"
                v-if="viewType == 'current'"
              >
                <span class="ml-1">{{$t('label.viewByLevel')}}</span>
              </CButton>
            </li>
            <li class="mb-1 mt-4">
              <CButton
                block
                size="sm"
                color="secondary"
                class="d-flex align-items-center justify-content-center"
                @click="view = '' , level = 0, preferences = [], viewType = 'current'"
                v-if="viewType == 'level'"
              >
                <span class="ml-1">{{$t('label.planView')}}</span>
              </CButton>
            </li>

            <li class="mb-1 mt-4">
              <CButton
                block
                size="sm"
                color="secondary"
                class="d-flex align-items-center justify-content-center"
                @click.stop="legendModal = true"
              >
                <CIcon name="cil-eye" />
                <span class="ml-1">{{$t('label.legend')}}</span>
              </CButton>
            </li>
            <li class="mb-1">
              <CButton
                block
                size="sm"
                color="secondary"
                class="d-flex align-items-center justify-content-center"
                :disabled="loading"
                @click.stop="notPositionedContainers()"
              >
                <span class="ml-1">{{$t('label.notPositioned')}}</span>
              </CButton>
            </li>
            <li class="mb-1" v-if="this.NotValidPosJson.length!=0">
              <CButton
                block
                size="sm"
                color="secondary"
                class="d-flex align-items-center justify-content-center"
                :disabled="loading"
                @click.stop="InvalidPosition()"
              >
                <span class="animation-item text-danger" style="display: inline-block;">
                  <CIcon name='Alerts' style="width: 1.2rem; height: 1.2rem; font-size: 1.5rem;"/>
                </span>
                <span class="ml-1">{{$t('label.invalidPosition')}}</span>
              </CButton>
            </li>
            <li class="mb-1">
              <CButton
                block
                size="sm"
                color="secondary"
                class="d-flex align-items-center justify-content-center"
                :disabled="loading"
                @click.stop="refreshData"
              >
                <CIcon name="cil-reload"/>
                <span class="ml-1">{{$t('label.refresh')}}</span>
              </CButton>
            </li>

            <li class="mb-1 TogglerPdf" style="position: relative;">
              <div style="cursor: pointer;" @click="ClickDropdown">
                <CIcon class="position-icon" name="cil-print"/>
              </div>
              <CDropdown id="Dropdown-prinf" addTogglerClasses="Toggler-Botton p-1" color="secondary" :togglerText="$t('label.print')">
                <div class="col-sm-12" v-for="level in levels">
                  <CInputCheckbox
                    custom
                    class="checkbook-filter ml-1"
                    :label="level.label"
                    :checked="VerifiedLevels.some(item => item.value == level.value)"
                    @update:checked="addRemoveLevel($event, level)"
                  />
                </div>
                <div class="col-sm-12 mt-3">
                  <CButton
                    size="sm"
                    color="add"
                    :disabled="loading"
                    style="height: auto;"
                    @click.stop="printPdf"
                  >
                    <span>{{$t('label.print')}}</span>
                  </CButton>
                </div>
              </CDropdown>
            </li>
          </ul>
        </div>
      </nav>

      <!-- Page Content  -->
      <div id="content">
        <!-- Navbar -->
        <CNavbar
          expandable="lg"
          light class="menu-view-cargo no-margin"
          color="secondary"
        >
           <div class="sidebar-controls">
            <CButton
              color="secondary"
              shape="square"
              class="mr-2"
              size="sm"
              @click.stop="toggleSidebar"
            >
              <CIcon name="cil-menu" />
              <span class="sr-only">Toggle Menu</span>
            </CButton>

            <CButton
              color="primary"
              shape="square"
              size="sm"
              :disabled="isMinimumZoom"
              @click.stop="minusZoom"
            >
              <CIcon name="cil-zoom-out" />
            </CButton>
            <a
              href="#"
              class="zoom-number"
              @click.prevent="resetZoom"
            >
              {{ `${zoom}%` }}
            </a>
            <CButton
              color="primary"
              shape="square"
              size="sm"
              @click.stop="plusZoom"
            >
              <CIcon name="cil-zoom-in" />
            </CButton>
          </div>
          <CToggler in-navbar @click="collapsed = !collapsed" />
          <CCollapse :show="collapsed" navbar>
            <CNavbarNav>
              <div class="mr-3 ml-2 selects-container">
                <span>{{ `${$t('label.locateEquipment')}:` }}</span>
                <CInput
                  size="sm"
                  class="no-margin-select ml-2 mr-1"
                  v-model="searchCode"
                >
                </CInput>
                <CButton
                  color="primary"
                  shape="square"
                  size="sm"
                  @click="setSearch"
                >
                  <CIcon name="cil-search" />
                </CButton>
              </div>
              <div class="position-relative ml-2 selects-container" v-if="viewType == 'level'">
                <span>{{$t('label.level')}}</span>
                <CSelect
                  size="sm"
                  :placeholder="$t('label.select')"
                  class="no-margin-select ml-2"
                  :options="levels"
                  :value.sync="level"
                  :disabled="isEmpty(levels)"
                />
              </div>
              <div class="position-relative ml-2 selects-container" v-if="viewType == 'level'">
                <span>{{$t('label.viewType')}}</span>
                <CSelect
                  size="sm"
                  :placeholder="$t('label.select')"
                  class="no-margin-select ml-2 mr-1"
                  :options="viewsOptions"
                  :value.sync="view"
                  :disabled="isEmpty(views)"
                />
                <CButton
                  color="primary"
                  shape="square"
                  size="sm"
                  @click.stop="viewBottom()"
                  :disabled="isEmpty(preferences) || viewType === ''"
                  v-if="viewType == 'level'"
                >
                  <CIcon name="cil-filter"/>
                  <!--<span class="ml-1">{{$t('label.preferences')}}</span>-->
                </CButton>
              </div>
            </CNavbarNav>
          </CCollapse>
          <div class="btn-pagination" v-if="viewType == 'level'">
            <span>{{$t('label.level')}} {{ currentLevel }} {{$t('label.of')}} {{ numberOfLevels}}</span>
            <CButtonGroup class="ml-2">
              <CButton
                color="primary"
                shape="square"
                size="sm"
                v-c-tooltip="{
                  content: `${$t('label.first')} ${$t('label.level')}`,
                  placement: 'top-end',
                }"
                :disabled="leftBtnsDisabled"
                @click.stop="firstLevel"
              >
                <CIcon name="cil-chevron-double-left" />
              </CButton>
              <CButton
                color="primary"
                shape="square"
                size="sm"
                v-c-tooltip="{
                  content: $t('label.previous'),
                  placement: 'top-end',
                }"
                :disabled="leftBtnsDisabled"
                @click.stop="previousLevel"
              >
                <CIcon name="cil-arrow-thick-to-left" />
              </CButton>
              <CButton
                color="primary"
                shape="square"
                size="sm"
                v-c-tooltip="{
                  content: $t('label.next'),
                  placement: 'top-end',
                }"
                :disabled="rightBtnsDisabled"
                @click.stop="nextLevel"
              >
                <CIcon name="cil-arrow-thick-to-right" />
              </CButton>
              <CButton
                color="primary"
                shape="square"
                size="sm"
                v-c-tooltip="{
                  content: `${$t('label.last')} ${$t('label.level')}`,
                  placement: 'top-end',
                }"
                :disabled="rightBtnsDisabled"
                @click.stop="lastLevel"
              >
                <CIcon name="cil-chevron-double-right" />
              </CButton>
            </CButtonGroup>
          </div>
        </CNavbar>
        <!-- Contenido Interno -->
        <div class="container-fluid">
          <CRow class="mt-5 mb-3">
            <CCol sm="12">
              <div 
                class="zoom-container"
                :class="{ 
                  'grab-bing': !isOgZoom ? true:null,
                  'zoom-center': isLessThanOgZoom ? true:null,
                }"
              >
                <div
                  class="bay-view-container"
                  :style="{ width: `${zoom}%` }"
                  v-if="!loading"
                >

                  <YardSeventeen
                    v-if="currentYardId == 'C6E16D79-982D-4BFC-86B0-FF10251A5635'"
                    :layout="layoutByViewType"
                    :level="level"
                    :viewType="viewType"
                    :preferences="currentPreferences"
                    tooltip
                    :zoom="zoom"
                    :AuxiliarBlock = "AuxiliarBlockJson"
                    :RecognitionZone = "RecognitionZoneJson"
                    @positionSubmit = "refreshData"
                  />
                  <YardTwelve
                    v-if="currentYardId == '35E02EE5-8478-4AA2-8EE0-41817EF91B4D'"
                    :layout="layoutByViewType"
                    :level="level"
                    :viewType="viewType"
                    :preferences="currentPreferences"
                    tooltip
                    :zoom="zoom"
                    :AuxiliarBlock = "AuxiliarBlockJson"
                    :RecognitionZone = "RecognitionZoneJson"
                    @positionSubmit = "refreshData"
                  />
                  <YardFour
                    v-if="currentYardId == 'C62F4B69-011F-ED11-A87E-000D3A0DEDC7'"
                    :layout="layoutByViewType"
                    :level="level"
                    :viewType="viewType"
                    :preferences="currentPreferences"
                    tooltip
                    :zoom="zoom"
                    :AuxiliarBlock = "AuxiliarBlockJson"
                    :RecognitionZone = "RecognitionZoneJson"
                    @positionSubmit = "refreshData"
                  />
                  <YardThirteen
                    v-if="currentYardId == 'F6513E84-8D61-4D20-8CCD-429AAEC6DDF4'"
                    :layout="layoutByViewType"
                    :level="level"
                    :viewType="viewType"
                    :preferences="currentPreferences"
                    tooltip
                    :zoom="zoom"
                    :AuxiliarBlock = "AuxiliarBlockJson"
                    :RecognitionZone = "RecognitionZoneJson"
                    @positionSubmit = "refreshData"
                  />
                  <GenericYard
                    v-if="currentYardId != 'C6E16D79-982D-4BFC-86B0-FF10251A5635' && currentYardId != '35E02EE5-8478-4AA2-8EE0-41817EF91B4D' && currentYardId != 'C62F4B69-011F-ED11-A87E-000D3A0DEDC7' && currentYardId != 'F6513E84-8D61-4D20-8CCD-429AAEC6DDF4'"
                    :layout="layoutByViewType"
                    :level="level"
                    :viewType="viewType"
                    :preferences="currentPreferences"
                    tooltip
                    :zoom="zoom"
                    :AuxiliarBlock = "AuxiliarBlockJson"
                    :RecognitionZone = "RecognitionZoneJson"
                    @positionSubmit = "refreshData"
                  />
                </div>
              </div>
            </CCol>
          </CRow>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { mapState } from 'vuex';
  import GeneralMixin from '@/_mixins/general';
  import Pagination from '@/_mixins/PaginateMixin';
  import BayMixin from "@/_mixins/bays";
  import Zoom from '@/_mixins/ZoomMixin';
  import YardGridMixin from '@/_mixins/yard-grid';
  import YardSeventeen from '@/pages/yard-setting/yards/yard-seventeen.vue';
  import YardTwelve from '@/pages/yard-setting/yards/yard-twelve';
  import YardFour from '@/pages/yard-setting/yards/yard-four';
  import YardThirteen from '@/pages/yard-setting/yards/yard-thirteen';
  import GenericYard from '@/pages/yard-setting/yards/pa-gen';
  import CoverWarehouse from '@/pages/yard-setting/yards/cover-warehouse';
  import PreferencesModal from './preferences-modal';
  import LegendModal from './legend-modal';
  import { dragscroll } from 'vue-dragscroll';
  import YardSlotModal from "@/pages/yard-setting/yards/yard-slot-modal";

  function created() {
    
  }
  //DATA-------------------------
  function data() {
    return {
      VerifiedLevels: [],
      showLayout: true,
      collapsed: false,
      //Filter
      multiple: false,
      normal: false,
      //Usadas para los filter de preferencias.
      filter: [],
      isLoadingFiltros: false,
      isLoadingPuertos: false,
      loading: false,
      //Situacion
      levels: [],
      level: 0,
      currentLevel: 1,
      situation: null,
      layoutZone: [],
      searchCode: '',
      viewType: 'level',
      layoutCurrent: [],
      //Preferencias
      views: [],
      view: [],
      preferences: [],
      selectedOptions: [],
      modalPref: false,
      //Leyenda
      legendModal: false,
    }
  }

  function toggleSidebar() {
    document.querySelector("#detailSidebar").classList.toggle('active');
  }
  
  function refreshBaysList(){}

  function toggleMultiple() {
    this.multiple = !this.multiple;
  }
  function toggleNormales() {
    this.normal = !this.normal;
  }
  function toggleMatching() {
    this.matchingModal = true;
  }
  function nextLevel() {
  this.currentLevel += 1;
  }
  function previousLevel() {
    this.currentLevel -= 1;
  }
  function firstLevel() {
    this.currentLevel = 1;
  }
  function lastLevel() {
    this.currentLevel = this.numberOfLevels;
  }
  function getCurrentSituation() {
    this.loading = true;
    this.$http
      .get("YardCurrentSituation-by-YardId", {
        YardId: this.currentYardId,
      })
      .then((response) => {
        this.situation = {...response.data.data[0]};
        
        this.layoutCurrent = [
          ...this.situation.CurrentJson[0].YardConfigDetailJson[0].AreaJson,
          ...this.situation.CurrentJson[0].YardConfigDetailJson[0].BlockJson,
        ];
        let total = this.situation.StructureJson[0].YardConfigDetailJson[0].HeightJson.length-1;
        this.levels = this.situation.StructureJson[0].YardConfigDetailJson[0].HeightJson.map((item) => 
          Object.assign({}, item, {
            label: item.HeightCode.replace(/\s+/g, ''),
            value: total--,
          })
        );
        this.levels.sort((p1, p2) => (p1.value > p2.value) ? 1 : (p1.value < p2.value) ? -1 : 0);
        this.level = 0;
        this.currentLevel = this.level+1;

        this.layoutZone = [
          ...this.situation.StructureJson[0].YardConfigDetailJson[0].AreaJson.filter(item =>{
            if(process.env.VUE_APP_TP_YARD_AREA_AUXILIAR_AREA == item.TpYardAreaId?.toUpperCase()){
              return item.CargoJson&&item.CargoJson.length!=0 ? true : false;
            }else{
              return true
            }
          }),
          ...this.levels[this.level]?.BlockJson ?? [],
        ];
        this.layoutZone = this.layoutZone.map((item) =>
          Object.assign({}, item, {
            resizable: true,
            elementType: item.YardBlockId ? 'block' : 'area',
            i: item.YardBlockId ? item.YardBlockId : item.YardAreaId,
          })
        );
        this.zoom = 100;
        this.CargoNoPosition = this.situation.CargoNoPositionJson;
        this.NotValidPosJson = this.situation?.CurrentJson[0]?.YardConfigDetailJson[0]?.NotValidPosJson ?? [];
        this.AuxiliarBlockJson =  this.situation.AuxiliarBlockJson;
        this.RecognitionZoneJson = this.situation.RecognitionZoneJson;
      })
      .catch((err) => {
        this.$notify({
          group: "container",
          title: "¡Error!",
          text: err,
          type: "error",
        });
      }).then(() => {
        this.loading = false;
      });
  }
  
  function setSearch() {
    let currentLevel = this.level, found = false
    this.levels.every((level) => {
      currentLevel = level.value;
      level.BlockJson.every((block) => {
        let find = block.PositionJsonAsc.find((item) => item.CargoJson && item.CargoJson.length != 0 && item.CargoJson[0].ContainerCode == this.searchCode.toUpperCase());
        if(find)
          found = true;

        return !found;
      });
      return !found;
    });
    let AreaFound = this.situation?.CurrentJson[0]?.YardConfigDetailJson[0]?.AreaJson.some(AreaJson => 
      AreaJson?.CargoJson.some(CargoJson => CargoJson?.ContainerCode?.toUpperCase()==this.searchCode.toUpperCase())
    )

    let ContainerNotPositioned = this.situation?.CargoNoPositionJson?.filter(container => 
      container?.ContainerCode?.toUpperCase()===this.searchCode.toUpperCase()
    )

    if(found||AreaFound||ContainerNotPositioned?.length>0){
      this.$store.state.yardsetting.search = this.searchCode.toUpperCase();
      if (found) {
        this.level = currentLevel;
      }
      if(ContainerNotPositioned?.length>0){
        this.$notify({
          group: "container",
          title: "¡Error!",
          text: this.$t('label.containerNotPositioned'),
          type: "error",
        });
        this.notPositionedSearch(ContainerNotPositioned);
      }
    } else {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: this.$t('label.noContainerFound'),
        type: "error",
      });
    }
  }
  async function getViews() {
    await this.$http
      .ejecutar("GET", "YardManagementView-list", {
        YardId: this.currentYardId,
      })
      .then((response) => {
        this.views = response.data.data;
      })
      .catch((err) => {
        this.$notify({
          group: "container",
          title: "¡Error!",
          text: err,
          type: "error",
        });
      });
  }
  function getPreferencias() {
    this.$http
      .ejecutar("GET", "YardManagementPreference-list", {
        YardViewId: this.view,
      })
      .then((response) => {
        this.preferences = response.data.data;
      })
      .catch((err) => {
        this.$notify({
          group: "container",
          title: "¡Error!",
          text: err,
          type: "error",
        });
      });
  }
  function handleSelecteds(options) {
    this.selectedOptions = [...options];
  }
  function isEmpty(arr) {
    return arr.length === 0;
  }
  function plusZoom() {
    this.zoom += 10;
  }
  function refreshData() {
    this.getCurrentSituation();
  }

  function addRemoveLevel(event, item) {
    if(event){
      //ADD
      this.VerifiedLevels.push(item);
    }
    else{
      //DELETE
      this.VerifiedLevels = this.VerifiedLevels.filter((e) =>e.value != item.value);
    }
  }

  function printPdf() {
    if (this.VerifiedLevels.length!=0) {
      let Nro = 0;
      let pageInfo = {
        yardInfo: this.yardData,
        levels: this.VerifiedLevels,
        areas: [
          ...this.situation.CurrentJson[0]?.YardConfigDetailJson[0]?.AreaJson?.filter(item => 
            item.CargoJson&&item.CargoJson.length!=0
          ),
        ],
        AreaContainers: this.NotValidPosJson ?? [],
      };
      this.situation?.CurrentJson[0]?.YardConfigDetailJson[0]?.AreaJson.forEach(item => {
        pageInfo.AreaContainers = pageInfo.AreaContainers.concat(item.CargoJson??[]);
      });

      pageInfo.AreaContainers = pageInfo.AreaContainers.map(item => {
        Nro++;
        return {...item, Nro: Nro};
      }),

      localStorage.setItem('pageInfo', JSON.stringify(pageInfo));

      let routeData = this.$router.resolve({name: 'PdfYardCurrentSituation'});
      window.open(routeData.href, '_blank');
    }else{

    }  
  }

  function ClickDropdown() {
    let ClickDropdown = document.getElementById("Dropdown-prinf");
    ClickDropdown.classList.toggle("show");
    ClickDropdown.lastChild.classList.toggle("show");
    console.log('hhh');
  }

  //Computeds
  function viewBottom() {
    if(!this.isEmpty(this.preferences) && this.viewType != '')
      this.modalPref = true
  }

  function numberOfLevels() {
    return this.levels.length;
  }
  function leftBtnsDisabled() {
    return this.currentLevel == 1;
  }
  function rightBtnsDisabled() {
    return this.currentLevel == this.numberOfLevels;
  }
  function currentYardId() {
    return this.yardData ? this.yardData.YardId : '';
  }
  function layoutByViewType() {
    return this.viewType == 'level' ? this.layoutZone : this.layoutCurrent;
  }

  function viewsOptions() {
    if(this.views.length === 0){
      return [{
        value: '', 
        label: this.$t('label.select'),
      }];
    }else{
      var chart = [{
        value: '', 
        label: this.$t('label.select'),
      }]

      let _lang = this.$i18n.locale;

      this.views.map(function(e){
          chart.push({
            label: _lang == 'es' ? e.YardViewNameEs : e.YardViewNameEn,
            value: e.YardViewId,
          })     
      })
      return chart;
    }
  }

  function currentPreferences() {
    return this.selectedOptions;
  }

  export default{
      name: 'yardCurrentSituation-index',
      data,
      mixins: [GeneralMixin],
      components: {
        YardSeventeen,
        YardTwelve,
        YardFour,
        YardThirteen,
        GenericYard,
        CoverWarehouse,
        PreferencesModal,
        LegendModal,
        YardSlotModal
      },
      mixins: [BayMixin, Pagination, Zoom, YardGridMixin],
      props: {
        modulo: {
          type: String,
          default: 'stowage'
        }
      },
      directives: {
        dragscroll
      },
      created,
      methods: {
        toggleSidebar,
        refreshBaysList,
        toggleMultiple,
        toggleNormales,
        toggleMatching,
        nextLevel,
        previousLevel,
        lastLevel,
        firstLevel,
        getCurrentSituation,
        setSearch,
        getViews,
        getPreferencias,
        isEmpty,
        handleSelecteds,
        plusZoom,
        refreshData,
        viewBottom,
        printPdf,
        addRemoveLevel,
        ClickDropdown,
      },
      computed: {
          numberOfLevels,
          leftBtnsDisabled,
          rightBtnsDisabled,
          currentYardId,
          layoutByViewType,
          viewsOptions,
          currentPreferences,
          ...mapState({
              yardData: state => state.yardManagement.yardData,
              currentTab: state => state.yardManagement.tabIndex,
              currentPrincipalTab: state => state.yardManagement.yardCollapse,
          })
      },
      watch:{
        currentTab: async function(newVal) {
          if(newVal == 1 && this.currentPrincipalTab == 1) {
            this.loading = true;
            this.DropdownPdf = false;
            this.NotValidPosJson = [];
            this.layoutZone = [];
            this.layoutCurrent = [];
            this.view = '';
            this.searchCode = '';
            this.preferences = [];
            this.selectedOptions = [];
            await this.getViews();
            this.getCurrentSituation();
          } else {
            this.VerifiedLevels = [];
            this.zoom = 99;
          }
        },
        currentLevel: function(newVal) {

          let levelAux = newVal - 1;
          this.level = levelAux;
          this.layoutZone = [
            ...this.situation.StructureJson[0].YardConfigDetailJson[0].AreaJson,
            ...this.levels[levelAux]?.BlockJson ?? [],
          ];
          this.layoutZone = this.layoutZone.map((item) =>
            Object.assign({}, item, {
              resizable: true,
              elementType: item.YardBlockId ? 'block' : 'area',
              i: item.YardBlockId ? item.YardBlockId : item.YardAreaId,
            })
          );
        },
        level: function(newVal, oldVal){

          if(newVal != oldVal){
            this.layoutZone = [
              ...this.situation.StructureJson[0].YardConfigDetailJson[0].AreaJson,
              ...this.levels[newVal]?.BlockJson ?? [],
            ];
            this.layoutZone = this.layoutZone.map((item) =>
              Object.assign({}, item, {
                resizable: true,
                elementType: item.YardBlockId ? 'block' : 'area',
                i: item.YardBlockId ? item.YardBlockId : item.YardAreaId,
              })
            );
            this.currentLevel = newVal + 1;
          }
        },
        searchCode: function(newVal, oldVal){
          if(newVal != oldVal){
            this.$store.state.yardsetting.search = '';
          }
        },
        view: function (newId, oldId) {
          if (newId && newId!='') {
            if(newId != oldId) this.selectedOptions = [];

            this.getPreferencias();
          }
          else {
            this.selectedOptions = [];
            this.preferences = [];
          } 
        },
      }
  }
</script>
<style>
  .Toggler-Botton{
    width: -webkit-fill-available;
    width: -moz-available;
    height: auto !important;
  }
  .TogglerPdf .dropdown-toggle::after {
    right: 20px !important;
  }
  .TogglerPdf .dropdown-menu{
    background-color: #ffffffd1;
    border-color: #0627584a;
    color: #000000;
  }
  .TogglerPdf .dropdown-item{
    color: #000000;
  }
  .TogglerPdf .dropdown-item:hover, .dropdown-item:focus{
    background-color:#ebedef;
  }
</style>
<style scoped>
.position-icon{
    position: absolute;
    top: 9px;
    left: 30px;
    z-index: 1;
  }
/* Bay View */
.bay-view-container {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  min-height: 100%;
  overflow: hidden;
}
.bay-view {
  position: relative;
  margin: 10px 5px;
  width: 100%;
}

/* Contenido de los Bays */
.bay-container {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 773px;
  position: relative;
}
.bay {
  position: relative;
  margin: 0 0 5px 5px;
  width: 100%;
  padding: 10px 0;
  /* overflow: hidden; */
}
.bay-info {
  /* position: absolute;
  bottom: -18px;
  right: -15px; */
  width: 165px;
  height: 85px;
  margin-left: auto
}
.bay-info ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

/* Zoom */
.zoom-container {
  width: 100%;
  max-height: 800px;
  overflow: auto;
  min-height: 560px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* scrollbar-width: thin; */
}
.zoom-center {
  display: flex;
  justify-content: center;
}
.sidebar-controls {
  display: flex;
  
  position: absolute;
  bottom: -32px;
  left: -1px;
  z-index: 3;
}
.zoom-number {
  padding:5px;
  background-color: #d6d7db;
  border: 1px solid #636f83;
  color: #000;
}
.zoom-number:hover {
  background-color: #636f83;
  color: #d8dbe0;
}
.grab-bing {
  cursor : -webkit-grab;
  cursor : -moz-grab;
  cursor : -o-grab;
  cursor : grab;
}
.grab-bing:active {
  cursor : -webkit-grabbing;
  cursor : -moz-grabbing;
  cursor : -o-grabbing;
  cursor : grabbing;
}

/* Sidebar */
.yard-body #detailSidebar {
  min-width: 200px !important;
  max-width: 200px !important;
  background-color: #e6e3e3 !important;
  background-image: none;
  border: 1px solid #000;
  transition: all 0.3s;
  z-index: 1;
}
.yard-body #content {
  min-height: 834px;
}
.sidebar-inner-space {
  /* margin-top: 2rem !important */
  margin-top: 0;
}
.yard-body #detailSidebar.active {
  width: 0;
	height: 0;
  margin: 0;
  padding: 0;
  border: 0;
  line-height: 0; /* sólo en caso de elementos inline-block */
  overflow: hidden;
  margin-left: -200px;
}
.btn-sidebar-detail {
  position: absolute;
  bottom: -32px;
  left: -1px;
  z-index: 3;
}

/* Navbar */
.menu-view-cargo.bg-secondary {
  background-color: #f5f7fa !important;
  border: 1px solid #636f83;
  /* border-top: none; */
  box-shadow: 0 6px 6px -6px #000;
}
.menu-view-cargo.no-margin {
  box-shadow: none;
  /* margin-bottom: 0; */
}
.nav-internal-item {
  color: #8994a8 !important;
}
.nav-internal-item:hover {
  color: #556279 !important;
}
.nav-internal-dropdown {
  background-color: #f5f7fa !important;
}
.no-margin-select {
  margin-bottom: 0;
}
.selects-container {
  display:flex; 
  justify-content: center;
  align-items: center;
}

/* Pagination */
.btn-pagination {
  position: absolute;
  bottom: -32px;
  right: 0;
  z-index: 3;

  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: bold;
}

/* Filtros multiples */
.filter-collapse {
  width: 100%;
  cursor: pointer;
  color: #4e4c4d;
  background-color: #f8f6f7;
  border: 1px solid #abaaa8;
  border-left: none;
  border-right: none;

  display: flex;
  justify-content: center;
  align-items: center;
}
.arrow-icon {
  transition: transform 0.15s ease-in;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
}
.arrow-icon.rotate {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.selects-container-general {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
}
</style>